@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import 'theme';
@keyframes spinner { to {transform: rotate(360deg);} }

// .mat-drawer-content .os-padding {
//   background-image: url('/assets/images/background_img.jpg');
//   background-repeat: no-repeat;
// }

// .background-image {
//   background-image: url('/assets/images/background_img.jpg');
//   background-repeat: no-repeat;
//   background-attachment: fixed;
// }

/** Buttons */
.btn-mr {
  margin: 0 4px 0 0 !important;
}

.btn-spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -10px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  border-top-color: #27509B;
  animation: spinner .8s linear infinite !important;
}

/** Mifa Table */
.mifa-table {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}

mat-card.mifa-table td.mat-cell {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.mat-sort-header-container {
  display: inline-flex !important;
}

/** Advanced Search */
.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-item-right {
  display: flex;
  justify-content: right;
  align-items: center;
}

div.dynamic-field div.mat-form-field-flex {
  padding-top: 0 !important;
}

div.dynamic-field div.mat-form-field-infix {
  padding-top: 2px !important;
  padding-bottom: 0 !important;
}

mic-sidebar-item a {
  text-align: left !important;
}

.mat-button-loading .mat-progress-spinner {
  margin-top: 7px !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  margin-bottom: 7px !important;
}
