@import '@michelin/theme/theming';

$theme: mat-light-theme(
  mat-palette($mic-blue, A400, A100, A700),
  mat-palette($mic-blue, A400, A100, A700),
  mat-palette($mic-red, A400, A100, A700)
);

// Init theme for design system
@include design-system-theme($theme, true);

.dark-mode {
  $dark-theme: mat-dark-theme(
    mat-palette($mic-dark-blue, A100, A700, A700),
    mat-palette($mic-dark-blue, A100, A400, A700),
    mat-palette($mic-red, A400, A100, A700)
  );
  // Init theme for design system
  @include design-system-theme($dark-theme, false);

  // .mat-drawer-container {
  //   background-color: #000 !important;
  // }

  // .mat-drawer-content .os-padding {
  //   background-image: url('/assets/images/background_img_dark.jpg') !important;
  // }

  // .background-image {
  //   background-image: url('/assets/images/background_img_dark.jpg');
  // }
}
